import React, { useState, useEffect } from 'react';
import Axios from 'axios';
import Layout from '../components/layout';
import LocalizedLink from '../utils/localized-link';
import { withForceUpdate } from '../i18n/with-force-update';

const ContactPage = ({ pageContext: { language }, t }) => {
  const DEFAULT_FORM_STATE = {
    email: '',
    name: '',
    object: '-1',
    message: '',
    privacyConsent: false,
    newsletterSubscribe: false,
    status: { type: null, message: '' },
  };

  const validateEmail = (elementValue) => {
    const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

    return emailPattern.test(elementValue);
  };

  const [formState, setFormState] = useState(DEFAULT_FORM_STATE);
  const [canSubmit, setCanSubmit] = useState(false);

  useEffect(() => {
    if (
      !validateEmail(formState.email) ||
      formState.name.length === 0 ||
      formState.message.length === 0 ||
      formState.object === '-1' ||
      !formState.privacyConsent
    ) {
      setCanSubmit(false);
    } else {
      setCanSubmit(true);
    }
  }, [formState, canSubmit, setCanSubmit]);

  const handleSubmit = (evt) => {
    evt.preventDefault();

    if (!canSubmit) {
      setFormState({
        ...formState,
        status: { type: 'error', message: t('Please fill all fields marked with an asterisk (*)') },
      });

      return;
    }

    Axios.post('https://getform.io/f/e0b6ba32-3630-4ea6-b4e1-d3d2500bd24b', {
      email: formState.email,
      name: formState.name,
      object: formState.object,
      message: formState.message,
    }).then(
      (res) => {
        setFormState({
          ...DEFAULT_FORM_STATE,
          status: {
            type: `success`,
            message: `${t('Your message has been successfully sent')}.
                        ${t("I'll do my best to reply as fast as possible!")}`,
          },
        });
      },
      () => {
        setFormState({
          ...formState,
          status: {
            type: `error`,
            message: `${t('An error occured, please try again')}.`,
          },
        });
      }
    );
  };

  return (
    <Layout title={t('Contact')} showTitle lang={language}>
      <form
        action="https://getform.io/f/e0b6ba32-3630-4ea6-b4e1-d3d2500bd24b"
        method="POST"
        onSubmit={handleSubmit}
        css={{
          display: `flex`,
          flexDirection: `column`,
          maxWidth: `600px`,
          margin: `auto`,
          '& input, & textarea, & select': {
            margin: `5px`,
            fontFamily: `LatoLatinWeb`,
            boxSizing: `border-box`,
            padding: `1px`,
            paddingLeft: `15px`,
          },
          '& input:focus, & textarea:focus, & select:focus': {
            padding: `0px`,
            paddingLeft: `14px`,
            border: `1px solid #dfac47`,
          },
        }}
      >
        <p>
          {t(
            "Have you got questions about the content published here ? Are you looking for a freelance developer? Let's start the discussion !"
          )}
        </p>
        <div
          css={{
            display: `flex`,
            flexDirection: `row`,
            '@media (max-width: 768px)': {
              flexDirection: `column`,
            },
          }}
        >
          <input
            value={formState.name}
            onChange={(e) => setFormState({ ...formState, name: e.target.value })}
            css={{
              border: `none`,
              outline: `none`,
              textAlign: `left`,
              paddingLeft: `15px`,
              backgroundColor: `#EBEDEA`,
              // width: `400px`,
              height: `40px`,
              '@media (max-width: 768px)': {
                flex: `auto`,
              },
              flex: 6,
            }}
            type="text"
            name="name"
            placeholder={`${t('Your name')}*`}
          />
          <input
            value={formState.email}
            onChange={(e) => setFormState({ ...formState, email: e.target.value })}
            css={{
              border: `none`,
              outline: `none`,
              textAlign: `left`,
              paddingLeft: `15px`,
              backgroundColor: `#EBEDEA`,
              // width: `400px`,
              height: `40px`,
              '@media (max-width: 768px)': {
                flex: `auto`,
              },
              flex: 6,
            }}
            type="email"
            name="email"
            placeholder={`${t('Your email')}*`}
          />
        </div>
        <select
          value={formState.object}
          onChange={(e) => setFormState({ ...formState, object: e.target.value })}
          name="object"
          css={{
            border: `none`,
            outline: `none`,
            textAlign: `left`,
            paddingLeft: `15px`,
            backgroundColor: `#EBEDEA`,
            minHeight: `40px`,
            '@media (max-width: 768px)': {},
            flex: 12,
          }}
        >
          <option disabled value="-1">
            {t('Choose a topic')}*
          </option>
          <option>{t('Mission proposal')}</option>
          <option>{t('Quote request')}</option>
          <option>{t('Suggestion')}</option>
          <option>{t('Bug on this website')}</option>
          <option>{t('Other')}</option>
        </select>
        <textarea
          value={formState.message}
          onChange={(e) => setFormState({ ...formState, message: e.target.value })}
          name="message"
          placeholder={`${t('Your message')}*`}
          css={{
            resize: `none`,
            border: `none`,
            outline: `none`,
            textAlign: `left`,
            paddingLeft: `15px`,
            backgroundColor: `#EBEDEA`,
            height: `200px`,
            '@media (max-width: 768px)': {},
          }}
        />
        <h4 css={{ fontWeight: 200, margin: `10px 0` }}>{t('GDPR Agreement')}</h4>
        <div>
          <h6 css={{ fontWeight: 200, margin: `10px 0` }}>
            {t(
              'This form collects your informations in order to reply to your message and add you to this website newsletter list'
            )}
            .&nbsp;
            {t('Checkout our')}
            &nbsp;
            <LocalizedLink to="/privacy" target="_blank">
              {t('privacy policy')}
            </LocalizedLink>
            &nbsp;
            {t('for further details')}
          </h6>
          <input
            type="checkbox"
            id="gdpr1"
            value={formState.privacyConsent}
            onChange={(e) => setFormState({ ...formState, privacyConsent: e.target.checked })}
          />
          <label htmlFor="gdpr1">
            {t('I consent to having this website store my submitted information so they can respond to my inquiry')}*
          </label>
          <br />
          <input
            type="checkbox"
            id="gdpr2"
            value={formState.newsletterSubscribe}
            onChange={(e) => setFormState({ ...formState, newsletterSubscribe: e.target.checked })}
          />
          <label htmlFor="gdpr2">
            ({t('optional')}) &nbsp;
            {t('I wish to subscribe to this website newsletter')}.
          </label>
        </div>
        {formState.status.type && (
          <h6
            css={{
              textAlign: `center`,
              fontWeight: 300,
              margin: 0,
              marginTop: `10px`,
              color: formState.status.type === 'error' ? `red` : `green`,
            }}
            dangerouslySetInnerHTML={{ __html: formState.status.message }}
          />
        )}
        <button
          disabled={!canSubmit}
          type="submit"
          css={{
            fontFamily: `LatoLatinWebMedium`,
            outline: `none`,
            border: `none`,
            background: `#dfac47`,
            height: `40px`,
            width: `fit-content`,
            padding: `0 25px`,
            color: `white`,
            cursor: `pointer`,
            textAlign: `center`,
            margin: `15px auto`,
            transition: `all .1s ease-in`,
            ':disabled': {
              background: `#444444`,
              cursor: `default`,
              color: `grey`,
            },
            ':disabled:hover': {
              background: `#444444`,
              cursor: `default`,
              color: `grey`,
            },
            '&:hover': {
              color: `#dfac47`,
              background: `white`,
            },
            '@media (max-width: 768px)': {
              marginLeft: `0px`,
              width: `100%`,
            },
          }}
        >
          {t('Send')}
        </button>
      </form>
    </Layout>
  );
};

export default withForceUpdate(ContactPage);
